html body {

  font-family: 'Times New Roman', Times, serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #65728d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
h1 {
  font-size: 24px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.respuesta {
  text-align: left;
  font-size: 16px;
  box-shadow: 10px 8px 2px gray;
  padding: 10px;
  border:  2px blue;
  background-color: rgb(200, 216, 191);
}

nav {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 5px;
  
  /* padding-bottom: 20px ; */

}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
 
  box-shadow: 0 4px 8px 0 rgba(131, 130, 130, 0.2), 0 6px 20px 0 rgba(131, 131, 131, 0.19);

}

nav li {
  float: left;

}

nav li a {
  display: block;
  color: #333;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  /* margin-top: 50px;
  padding-top: 60px; */
}

nav li a:hover {
  background-color: #ddd;
}

.button {
  float: none;
  position: relative;
  font-size: 20px;
  /* display: inline-flex; */ 
}

.button1 {
  
  background-color: rgb(160, 160, 161, 0.3);
  border: 2px rgb(204, 202, 202);
  border-radius: 10px;
  padding-left: 10px;
  margin: 10px;
}
.botons {
  padding: 10px;
  margin: 10px;
  border: 2px rgb(119, 41, 41);
  border-radius: 10px;
  text-align: right;
  color: #1a1919;
  box-shadow: 2px 5px 2px rgb(119, 119, 119);
}

.prompt {
  float: none;
  padding-bottom: 10px;
  position: relative;
  text-align:center;
  
}
.h11 {
  padding: 20px;
  /* margin: 20px; */
  border: 2px blue;
  float: none;
  text-align: center;
  color: rgb(33, 33, 39);
  text-shadow: 2px 2px 2px rgb(182, 218, 233);
  background-color: rgb(226, 121, 35);
  box-shadow: 2px 4px 2px rgb(182, 218, 233);
}
.footer {
  padding: 20px;
  font-size: 28px;
  /* margin: 20px; */
  border: 2px blue;
  float: none;
  text-align: center;
  color: rgb(33, 33, 39);
  text-shadow: 2px 2px 2px rgb(182, 218, 233);
  background-color: rgb(226, 121, 35);
  box-shadow: 2px 4px 2px rgb(182, 218, 233);
}
.tile_div button {
  display: block;
  float: left;
  height: 50px;
  width: 100px;
  margin-right: 5px;
  /* background-image: url(./images/button_left.png); */
  text-align: center;
  line-height: 50px;
  text-decoration: none;
}

#div1 {
  width: 100px;
  height: 100px;
  background-color: red;
  position: relative;
  animation-name: example;
  animation-duration: 4s;
  animation-direction: reverse;
  animation-iteration-count: infinite;  
  -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

@keyframes example {
  0%   {background-color:red; left:0px; top:0px;}
  25%  {background-color:yellow; left:200px; top:0px;}
  50%  {background-color:blue; left:200px; top:200px;}
  75%  {background-color:green; left:0px; top:200px;}
  100% {background-color:red; left:0px; top:0px;}
  
}

@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}
.boxlogo {
  width: 100px;
  height: 100px;
  /* background-color: blue; */
  animation: move 2s ease-in-out infinite alternate;
}


@keyframes move {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(250px);
  }
}

.textlogo {
  position: relative;
  width: 250px;
  height: 60px;
  color: #2b1cfc;
  /* background-color: rgb(120, 120, 231); */
  animation: movetext 2s ease-in-out infinite alternate;
}
@keyframes movetext {
  from {
    transform: translateX(250px);
  }
  to {
    transform: translateX(0);
  }
}
img {
  border-radius: 10px;
}
.slogan {
  text-align: right;
  font-size: larger;
  color: #050505;
  font-weight: bold;
}
.resources {
  list-style-type: none;
font-size: 20px;
}
.resources a {
  text-decoration: none;
  
}
  